import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import wave from '../../static/wave-line.svg'

import Layout from '../components/Layout.js'

export default ({ data }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <div class="post__container">
        <h1 class="post__title center-text">{post.frontmatter.title}</h1>
        <h3 class="post__date center-text negative-margin">
          {post.frontmatter.date}
        </h3>
        <img src={wave} alt="waves" class="post__line" />

        <div
          class="post__gist post__blog"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <Link to="/" class="post__button home__button">
          <h3 class="center-text m-top">
            <span>&#10229;</span> Back to home
          </h3>
        </Link>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM, YYYY")
      }
    }
  }
`
